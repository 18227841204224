import { SiteConfig } from '../sites.ts';

const NKConfig: SiteConfig = {
  company: {
    name: "NowKast",
    tagline: "Revolutionizing social media through decentralized networks and ethical engagement incentives.",
    description: "Pioneer in mesh-networked social media platforms. Leveraging edge computing for cost-effective content delivery. Rewarding authentic engagement and consensus building. Creating sustainable, non-predatory social networks.",
    details: "NowKast is reimagining social media through an innovative platform that leverages mesh networking and edge computing to dramatically reduce infrastructure costs, enabling a social network that prioritizes user value over predatory monetization. By distributing content delivery across user devices and local edge nodes, NowKast creates a more efficient and cost-effective platform that can focus on fostering genuine connection and dialogue.\n\nOur revolutionary reward system incentivizes constructive behavior, open-mindedness, and consensus-building rather than divisive engagement. Through sophisticated algorithms that measure the quality of interactions, fact-checking contributions, and bridge-building between different viewpoints, users earn recognition and rewards for fostering understanding and truth rather than conflict and sensationalism.\n\nThe platform's distributed architecture not only reduces costs but also enhances content delivery speed and reliability. By utilizing mesh networking technology, NowKast creates resilient local content networks that continue functioning even during connectivity disruptions. This approach also enables more efficient content distribution, reducing bandwidth costs while improving user experience.\n\nNowKast's commitment to truth and consensus is embedded in its core features, including collaborative fact-checking mechanisms, bridge-building rewards for users who facilitate understanding across different viewpoints, and transparency metrics that highlight both the diversity and quality of user interactions. This creates a social platform where truth and understanding naturally rise above misinformation and division."
  },
  theme: {
    primary: "#4A90E2",
    secondary: "#2C3E50",
    accent: "#E74C3C",
    background: "#F5F5F5",
    text: "#333333"
  },
  navigation: {
    portfolioLabel: "Portfolio",
    showTechnology: true,
    showSolutions: true,
    showBlog: true,
    showNews: true,
    showHelp: true,
    showAuth: true
  },
  footerLinks: [
    { label: "About", href: "/about" },
    { label: "Contact", href: "/contact" },
    { label: "Privacy", href: "/privacy" }
  ],
  aboutPage: {
    title: "About NowKast",
    subtitle: "Building the Future of Social Media",
    mission: {
      title: "Our Mission",
      content: "To create social networks that unite rather than divide communities, reward authentic engagement, and establish sustainable platforms for meaningful interaction."
    },
    team: [],
    values: [
      {
        title: "Truth and Transparency",
        description: "We believe in fostering honest and open communication"
      },
      {
        title: "Open-mindedness",
        description: "We embrace diverse perspectives and encourage constructive dialogue"
      },
      {
        title: "Consensus Building",
        description: "We prioritize finding common ground and mutual understanding"
      },
      {
        title: "Sustainable Operations",
        description: "We build efficient, environmentally conscious solutions"
      }
    ]
  },
  portfolioPage: {
    title: "Our Portfolio",
    subtitle: "Innovative Social Media Solutions",
    companies: [],
    investmentCriteria: []
  },
  offerings: [
    {
      type: "product",
      name: "NowKast Platform",
      description: "Mesh-networked social media platform with ethical engagement incentives",
      features: [
        "Distributed content delivery",
        "Edge computing integration",
        "Consensus-building rewards",
        "Truth verification system"
      ],
      benefits: [
        "Reduced infrastructure costs",
        "Improved content delivery",
        "Authentic engagement",
        "Community-driven truth"
      ]
    },
    {
      type: "service",
      name: "Community Building Tools",
      description: "Suite of tools for fostering healthy online communities",
      features: [
        "Bridge-building metrics",
        "Collaborative fact-checking",
        "Engagement quality analysis",
        "Consensus tracking"
      ],
      benefits: [
        "Healthier discussions",
        "Reduced polarization",
        "Enhanced understanding",
        "Truth-focused dialogue"
      ]
    }
  ],
  values: [
    "Truth and Transparency",
    "Open-mindedness",
    "Consensus Building",
    "Sustainable Operations"
  ],
  beliefs: [
    "Social media should unite, not divide",
    "Truth emerges through open dialogue",
    "Technology should serve humanity",
    "Sustainable platforms enable better content"
  ],
  norms: [
    "Truth-seeking behavior",
    "Constructive engagement",
    "Bridge-building dialogue",
    "Community support"
  ],
  image_prompts: [
    "Abstract visualization of a mesh network with interconnected nodes glowing with warm, inviting colors, representing community connections",
    "Modern interface showing consensus-building metrics with bridges forming between different viewpoint clusters",
    "Dynamic 3D representation of edge computing nodes distributing social content across a community network",
    "Professional illustration of truth verification process with collaborative fact-checking visualization",
    "Artistic interpretation of bridge-building rewards showing connections forming across different community groups",
    "Clean, minimal dashboard displaying engagement quality metrics and community health indicators",
    "Isometric visualization of content distribution through mesh network with edge computing nodes",
    "Split-screen showing traditional vs. NowKast social media dynamics, highlighting reduced polarization",
    "Abstract representation of truth emerging through consensus with flowing data streams converging",
    "Modern social media interface emphasizing connection and understanding rather than conflict"
  ]
};

export default NKConfig;
