import metadataConfig from "./sites/MDAM.ts";
import chainTokenomicsConfig from "./sites/CTIO.ts";
import dataAssetConfig from "./sites/DAS.ts";
import zeroServerConfig from "./sites/ZS.ts";
import sightlineConfig from "./sites/SII.ts";
import globalDevOpsConfig from "./sites/GDO.ts";
import syncSessionConfig from "./sites/SyncSession.ts";
import DTMConfig from "./sites/DTM.ts";
import GDOConfig from "./sites/GDO.ts";
import MDAMConfig from "./sites/MDAM.ts";
import NKConfig from "./sites/NK.ts";

// Default navigation configuration
const DEFAULT_NAV_CONFIG = {
  portfolioLabel: "Portfolio",
  showTechnology: true,
  showSolutions: true,
  showBlog: true,
  showNews: true,
  showHelp: true,
  showAuth: true,
};

export interface TeamMember {
  name: string;
  role: string;
  background?: string;
  bio?: string;
}

export interface Partnership {
  company: string;
  role: string;
}

export interface Offering {
  type: "product" | "service" | "technology" | "project";
  name: string;
  description: string;
  features: string[];
  benefits: string[];
}

export interface TechnologyStack {
  core_competencies?: {
    name: string;
    areas: string[];
  }[];
  development_stack?: {
    frontend: string[];
    backend: string[];
    infrastructure: string[];
    blockchain?: string[];
  };
  innovation_areas?: {
    name: string;
    focus: string[];
  }[];
}

export interface Contact {
  email?: string;
  phone?: string;
}

export interface CompanyDetails {
  name: string;
  tagline: string;
  description: string;
  details?: string;
  logo?: {
    svg: string;
  };
}

export interface Value {
  title: string;
  description: string;
}

export interface Mission {
  title: string;
  content: string;
}

export interface Company {
  name: string;
  description: string;
  status: string;
  year: number;
}

export interface InvestmentCriterion {
  title: string;
  description: string;
}

export interface SiteConfig {
  company: CompanyDetails;
  theme: {
    primary: string;
    secondary: string;
    accent: string;
    background: string;
    text: string;
  };
  navigation?: {
    portfolioLabel?: string;
    showTechnology?: boolean;
    showSolutions?: boolean;
    showBlog?: boolean;
    showNews?: boolean;
    showHelp?: boolean;
    showAuth?: boolean;
  };
  footerLinks: {
    label: string;
    href: string;
  }[];
  aboutPage: {
    title: string;
    subtitle: string;
    mission: Mission;
    team: TeamMember[];
    values: Value[];
  };
  portfolioPage: {
    title: string;
    subtitle: string;
    companies: Company[];
    investmentCriteria: InvestmentCriterion[];
  };
  solutionsPage?: {
    title?: string;
    subtitle?: string;
    content?: string;
    markdown?: string;
  };
  technologyPage?: {
    title?: string;
    subtitle?: string;
    markdown?: string;
  };
  homePage?: {
    hero?: {
      title?: string;
      highlight?: string;
      description?: string;
    };
    config?: {
      showPromoVideo?: boolean;
      showContactForm?: boolean;
    };
  };
  media?: {
    promoVideo?: string;
    promoVideoPoster?: string;
  };
  technology?: TechnologyStack;
  partnerships?: Partnership[];
  values?: string[];
  beliefs?: string[];
  norms?: string[];
  image_prompts?: string[];
  contact?: Contact;
  offerings?: Offering[];
}

let currentSite: string | undefined;

export function initializeSite(siteName: string) {
  currentSite = siteName;
}

export function getSiteConfig(overrideSite?: string): SiteConfig {
  // First try to use the override if provided
  let site = overrideSite || currentSite;
  
  // Then try to get from window if available
  if (!site && typeof window !== 'undefined') {
    site = window.SITE_NAME;
  }

  if (!site) {
    throw new Error("Site not initialized. Call initializeSite first or ensure window.SITE_NAME is set.");
  }

  console.log(`Getting config for site: ${site}`);

  let config: SiteConfig;
  switch (site) {
    case 'chaintokenomics':
      config = chainTokenomicsConfig;
      break;
    case 'dataasset':
      config = dataAssetConfig;
      break;
    case 'metadata':
      config = metadataConfig;
      break;
    case 'zeroserver':
      config = zeroServerConfig;
      break;
    case 'sightline':
      config = sightlineConfig;
      break;
    case 'globaldevops':
      config = globalDevOpsConfig;
      break;
    case 'syncsession':
      config = syncSessionConfig;
      break;
    case 'dtm':
      config = DTMConfig;
      break;
    case 'gdo':
      config = GDOConfig;
      break;
    case 'mdam':
      config = MDAMConfig;
      break;
    case 'nk':
      config = NKConfig;
      break;
    default:
      throw new Error(`Unknown site: ${site}`);
  }

  // Merge navigation config with defaults
  config.navigation = {
    ...DEFAULT_NAV_CONFIG,
    ...config.navigation
  };

  return config;
}
