import { SiteConfig } from '../sites.ts';

const MDAMConfig: SiteConfig = {
  company: {
    name: "MDAM Group",
    tagline: "Canada's premier AI-Agent venture studio, combining strategic advisory, operational expertise, and investment in breakthrough technologies.",
    description: "First venture studio specializing in AI-Agent company creation and scaling. Led by elite technology operators and strategic advisors. Focused on AI, Ethereum blockchain, and edge computing innovations. Accelerating Canadian startup growth through hands-on venture building.",
    details: "MDAM Group stands as Canada's first venture studio specifically focused on creating and scaling AI-Agent companies. By combining deep expertise in artificial intelligence, Ethereum blockchain, and edge computing, we identify and develop breakthrough technology ventures that define the future of autonomous systems and decentralized operations.\n\nOur venture studio is led by accomplished technology operators and strategic advisors who bring decades of experience in building and scaling successful technology companies. This elite team provides hands-on operational support, strategic guidance, and direct investment to accelerate the growth of promising Canadian startups in our focus areas.\n\nWe take a unique approach to venture building, working closely with founding teams to develop and implement AI-first strategies that leverage emerging technologies. Our expertise spans the full spectrum of artificial intelligence, from large language models to autonomous agents, combined with deep understanding of Ethereum blockchain infrastructure and edge computing architectures.\n\nThrough our comprehensive studio model, we provide portfolio companies with not just capital, but also strategic advisory services, technical expertise, and operational support. This hands-on approach ensures our ventures have the resources and guidance needed to successfully navigate the complex landscape of AI and blockchain technology development."
  },
  theme: {
    primary: "#1a237e",
    secondary: "#0d47a1",
    accent: "#2962ff",
    background: "#ffffff",
    text: "#000000"
  },
  navigation: {
    portfolioLabel: "Portfolio",
    showTechnology: true,
    showSolutions: true,
    showBlog: true,
    showNews: true,
    showHelp: true,
    showAuth: true
  },
  footerLinks: [
    { label: "Contact", href: "/contact" },
    { label: "Privacy", href: "/privacy" },
    { label: "Terms", href: "/terms" }
  ],
  technology: {
    core_competencies: [
      {
        name: "AI and Machine Learning",
        areas: [
          "Large Language Models (LLMs)",
          "Autonomous Agent Systems",
          "Neural Network Architecture",
          "Machine Learning Operations",
          "AI Model Optimization",
          "Natural Language Processing"
        ]
      },
      {
        name: "Blockchain Technology",
        areas: [
          "Ethereum Smart Contracts",
          "DeFi Protocol Development",
          "Token Economics",
          "Consensus Mechanisms",
          "Layer 2 Solutions",
          "Web3 Infrastructure"
        ]
      },
      {
        name: "Edge Computing",
        areas: [
          "Distributed Systems",
          "Mesh Networks",
          "Edge AI Implementation",
          "Low-latency Computing",
          "Resource Optimization",
          "Edge Security"
        ]
      }
    ],
    development_stack: {
      frontend: [
        "React/Next.js",
        "TypeScript",
        "WebAssembly",
        "Progressive Web Apps"
      ],
      backend: [
        "Rust",
        "Go",
        "Node.js",
        "Python"
      ],
      infrastructure: [
        "Kubernetes",
        "Docker",
        "AWS/Azure/GCP",
        "Edge Computing Platforms"
      ],
      blockchain: [
        "Solidity",
        "Web3.js",
        "Ethereum Tools",
        "IPFS"
      ]
    },
    innovation_areas: [
      {
        name: "Autonomous Systems",
        focus: [
          "AI Agent Architecture",
          "Multi-agent Systems",
          "Agent Orchestration",
          "Behavioral Programming"
        ]
      },
      {
        name: "Decentralized Computing",
        focus: [
          "P2P Networks",
          "Distributed Storage",
          "Edge Processing",
          "Mesh Networking"
        ]
      },
      {
        name: "Enterprise AI",
        focus: [
          "LLM Integration",
          "Custom AI Models",
          "Process Automation",
          "Decision Systems"
        ]
      }
    ]
  },
  offerings: [
    {
      type: "service",
      name: "Venture Studio",
      description: "Comprehensive venture building and scaling platform for AI-Agent companies",
      features: [
        "Company creation and incubation",
        "Strategic technology advisory",
        "Operational support and expertise",
        "Direct investment and funding"
      ],
      benefits: [
        "Accelerated growth trajectory",
        "Access to elite expertise",
        "Operational excellence",
        "Strategic capital deployment"
      ]
    },
    {
      type: "service",
      name: "Strategic Advisory",
      description: "Expert guidance in AI, blockchain, and edge computing strategy",
      features: [
        "Technology strategy development",
        "AI implementation planning",
        "Blockchain architecture design",
        "Edge computing optimization"
      ],
      benefits: [
        "Strategic direction",
        "Technical excellence",
        "Market positioning",
        "Competitive advantage"
      ]
    }
  ],
  values: [
    "Technical Excellence",
    "Innovation Leadership",
    "Hands-on Execution",
    "Canadian Technology Growth"
  ],
  beliefs: [
    "AI-Agents are the future of technology",
    "Hands-on venture building drives success",
    "Canadian startups can lead globally",
    "Deep expertise enables innovation"
  ],
  norms: [
    "Excellence in execution",
    "Technology-first approach",
    "Collaborative innovation",
    "Strategic focus"
  ],
  contact: {
    email: "info@metadataassetmanagement.com",
    phone: "+1 (416) 817-6499"
  },
  image_prompts: [
    "Modern venture studio workspace with holographic displays showing AI agent architectures and blockchain analytics",
    "Professional team collaboration scene with experts reviewing technical diagrams and development plans",
    "Dynamic visualization of the venture building process with connected stages and milestone indicators",
    "Abstract representation of AI-Agent technology with neural networks and autonomous system components",
    "Clean, corporate illustration of technology stack with emphasis on AI, blockchain, and edge computing",
    "Split-screen showing venture development stages from concept to scaled deployment",
    "Isometric visualization of comprehensive venture support services and resources",
    "Professional 3D rendering of technical advisory sessions with holographic data displays",
    "Artistic interpretation of Canadian technology leadership with innovative tech elements",
    "Technical diagram of venture studio methodology with clear process flows and support systems"
  ]
};

export default MDAMConfig;
