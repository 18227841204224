import { SiteConfig } from '../sites.ts';

const dataAssetConfig: SiteConfig = {
  company: {
    name: "DataAsset.Store",
    tagline: "Protecting and maximizing enterprise value through sovereign AI data management and compliance solutions.",
    description: "Leading provider of AI data sovereignty and management solutions. Specializing in protecting enterprise value in the AI era. Delivering secure LLM frameworks and sovereign network solutions. Enabling organizations to maintain control of their AI data assets.",
    details: "DataAsset.Store (DAS) stands at the forefront of the AI revolution, providing crucial solutions for managing and protecting enterprise data in the age of large language models and AI agents. Our innovative DaLLM framework enables organizations to maintain sovereignty over their valuable data while leveraging the power of AI and LLMs.\n\nThrough our sovereign network technology, we enable organizations to efficiently utilize their internal compute resources for AI applications while maintaining complete control over their data flows. Our solutions create private networks that optimize local compute capacity for AI model execution, with selective capability to leverage external frontier models when needed.\n\nWe recognize that as AI becomes more integrated into business operations, the data flowing through AI systems becomes a core part of enterprise value. Our solutions protect this value by ensuring organizations maintain ownership of their data paths, customer relationships, and domain expertise. This is particularly crucial for professional services firms, such as law offices, that need to leverage AI while preserving their client relationships and expertise.\n\nDAS provides specialized packaging for government and regulated industries, combining sovereign computing capabilities with comprehensive compliance features. Our solutions include governance analysis tools and instantaneous metrics, ensuring organizations can safely adopt AI technologies while protecting their core business value from erosion."
  },
  theme: {
    primary: "#0066CC",
    secondary: "#001F3F",
    accent: "#00A3E0",
    background: "#FFFFFF",
    text: "#333333"
  },
  navigation: {
    portfolioLabel: "Portfolio",
    showTechnology: true,
    showSolutions: true,
    showBlog: true,
    showNews: true,
    showHelp: true,
    showAuth: true
  },
  footerLinks: [
    { label: "Privacy Policy", href: "/privacy" },
    { label: "Terms of Service", href: "/terms" },
    { label: "Contact", href: "/contact" }
  ],
  aboutPage: {
    title: "About DataAsset.Store",
    subtitle: "Leading the Future of AI Data Sovereignty",
    mission: {
      title: "Our Mission",
      content: "To protect and maximize enterprise value in the AI era through sovereign data management"
    },
    team: [
      {
        name: "Abdul Razzak Syed",
        role: "Chief Executive Officer",
        background: "CPA with extensive experience in compliance and enterprise governance"
      },
      {
        name: "Wallace Trenholm",
        role: "Chief Technology Officer",
        background: "Veteran AI practitioner with over 10 years of experience in artificial intelligence and data systems"
      },
      {
        name: "Hugh O'Reilly",
        role: "Director",
        background: "Expert in pension fund management with deep understanding of data management requirements in institutional settings"
      },
      {
        name: "Mai Mavinkurve",
        role: "Director",
        background: "Data Governance expert leading initiatives to implement P.Eng certification standards in AI infrastructure"
      }
    ],
    values: [
      {
        title: "Data Sovereignty",
        description: "Ensuring complete control over enterprise data assets"
      },
      {
        title: "Enterprise Value Protection",
        description: "Safeguarding core business value in the AI era"
      },
      {
        title: "Innovation Control",
        description: "Managing technological advancement responsibly"
      },
      {
        title: "Compliance Excellence",
        description: "Maintaining highest standards of regulatory compliance"
      }
    ]
  },
  portfolioPage: {
    title: "Our Solutions",
    subtitle: "Comprehensive AI Data Management Solutions",
    companies: [],
    investmentCriteria: []
  },
  homePage: {
    hero: {
      title: "Sovereign AI Data Management",
      highlight: "Protect and Maximize Enterprise Value",
      description: "Leading provider of AI data sovereignty and management solutions, specializing in protecting enterprise value in the AI era."
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  },
  media: {
    promoVideo: "https://storage.googleapis.com/public-das-web-assets/das-consulting.mp4"
  },
  partnerships: [
    {
      company: "Sightline Innovation Inc.",
      role: "Technology licensing partner for human-in-the-loop AI patents"
    },
    {
      company: "ZeroServer.org",
      role: "Strategic partner for open-source AI infrastructure and mesh networking"
    },
    {
      company: "GlobalDevOps Inc.",
      role: "Implementation partner for global deployment and operations"
    }
  ],
  values: [
    "Data Sovereignty",
    "Enterprise Value Protection",
    "Innovation Control",
    "Compliance Excellence"
  ],
  beliefs: [
    "Enterprise data is core business value",
    "AI integration must protect sovereignty",
    "Data control enables innovation",
    "Compliance enables trust"
  ],
  norms: [
    "Data-sovereign approach",
    "Value preservation",
    "Security-first mindset",
    "Controlled innovation"
  ],
  image_prompts: [
    "A futuristic secure data center with glowing blue network connections and a protective shield dome, representing data sovereignty",
    "Abstract visualization of AI data flows with clear boundaries and checkpoints, showing controlled data movement",
    "Modern office setting with holographic displays showing real-time data governance metrics and compliance indicators",
    "Artistic representation of a digital fortress protecting flowing data streams, emphasizing security and control",
    "Professional team working with advanced AI interfaces while maintaining clear data boundaries and controls",
    "Isometric illustration of a sovereign network architecture with connected nodes and secure pathways",
    "Close-up of an AI dashboard showing compliance metrics and data flow controls in a clean, professional interface",
    "Conceptual visualization of data value preservation with protected enterprise assets in a secure digital vault",
    "Modern boardroom setting with executives reviewing 3D holographic data sovereignty reports",
    "Abstract representation of the DaLLM framework showing seamless integration of AI while maintaining data control"
  ],
  offerings: [
    {
      type: "product",
      name: "DaLLM Framework",
      description: "Enterprise framework for managing data in large language model applications",
      features: [
        "Sovereign networks for private infrastructure",
        "On-premise computing optimization",
        "Selective frontier model access",
        "Model fine-tuning capabilities",
        "Algorithmic processing enhancement"
      ],
      benefits: [
        "Maintained data sovereignty",
        "Enhanced accuracy for business problems",
        "Lower operational costs",
        "Protected enterprise value"
      ]
    },
    {
      type: "product",
      name: "SAICO Platform",
      description: "Sovereign AI Operations and Compliance platform for enterprise governance",
      features: [
        "Real-time AI operations monitoring",
        "Automated compliance checking",
        "Sovereign computing infrastructure",
        "Comprehensive audit trails",
        "Customizable compliance frameworks",
        "SOC1, SOC2, and ISO42001 compliance support"
      ],
      benefits: [
        "Complete operational oversight",
        "Regulatory compliance",
        "Secure governance controls",
        "Adaptable to different jurisdictions"
      ]
    },
    {
      type: "technology",
      name: "Human-in-the-Loop AI System",
      description: "Licensed technology from Sightline Innovation for AI systems requiring human oversight",
      features: [
        "Machine learning analysis pathways",
        "Message path escalation",
        "Human operator interaction systems",
        "Decision-making routing"
      ],
      benefits: [
        "Controlled AI deployment",
        "Appropriate human oversight",
        "Enhanced decision management",
        "Regulatory compliance"
      ]
    }
  ]
};

export default dataAssetConfig;
