import { SiteConfig } from '../sites.ts';

const DTMConfig: SiteConfig = {
  company: {
    name: "DigitalTwinMonitoring",
    tagline: "Bridging physical and virtual worlds through AI-powered digital twin technology and advanced sensor networks.",
    description: "Leaders in AI-driven digital twin monitoring and 3D modeling.\nSpecializing in complex real-world scene analysis and virtualization.\nDelivering sensor-integrated virtual landscape monitoring solutions.\nEnabling data-driven decision making through digital twin technology.",
    details: "DigitalTwinMonitoring (DTM) is at the forefront of digital twin technology, combining advanced sensor networks with sophisticated 3D modeling to create accurate, real-time virtual representations of complex physical environments. Our innovative approach integrates AI processing to analyze and interpret both physical sensor data and virtual landscape information, enabling unprecedented insights and monitoring capabilities.\n\nOur sensor integration platform combines data from multiple sources including IoT devices, environmental sensors, and spatial mapping systems to create comprehensive digital twins. These virtual replicas are continuously updated through our real-time processing pipeline, ensuring accurate representation of physical spaces and enabling sophisticated analysis of complex scenes and environments.\n\nDTM's AI-powered analysis engine processes the combined sensor and 3D model data to identify patterns, predict changes, and detect anomalies across both physical and virtual landscapes. This capability enables organizations to monitor complex environments, predict maintenance needs, and optimize operations through data-driven insights derived from our digital twin implementations.\n\nThrough our advanced visualization and monitoring systems, we provide organizations with intuitive interfaces to interact with their digital twins, enabling better decision-making through real-time access to comprehensive environmental data. Our solutions serve industries ranging from manufacturing and infrastructure to urban planning and environmental monitoring."
  },
  theme: {
    primary: "#2C3E50",    // Professional dark blue
    secondary: "#34495E",  // Complementary slate
    accent: "#3498DB",     // Technical blue
    background: "#ECF0F1", // Light gray
    text: "#2C3E50"        // Dark blue for text
  },
  navigation: {
    portfolioLabel: "Portfolio",
    showTechnology: true,
    showSolutions: true,
    showBlog: true,
    showNews: true,
    showHelp: true,
    showAuth: true,
  },
  footerLinks: [
    { label: "Contact", href: "/contact" },
    { label: "Privacy Policy", href: "/privacy" },
    { label: "Terms of Service", href: "/terms" }
  ],
  offerings: [
    {
      type: "product",
      name: "SensorTwin Platform",
      description: "Integrated sensor network and digital twin monitoring system",
      features: [
        "Multi-sensor data integration",
        "Real-time 3D modeling",
        "AI-powered scene analysis",
        "Environmental monitoring"
      ],
      benefits: [
        "Comprehensive environmental awareness",
        "Predictive maintenance capabilities",
        "Real-time monitoring and alerts",
        "Data-driven decision support"
      ]
    },
    {
      type: "service",
      name: "Virtual Landscape Analytics",
      description: "AI-powered analysis of digital twin environments",
      features: [
        "Complex scene interpretation",
        "Pattern recognition and analysis",
        "Anomaly detection",
        "Predictive modeling"
      ],
      benefits: [
        "Enhanced operational insights",
        "Improved risk management",
        "Optimized resource allocation",
        "Proactive maintenance planning"
      ]
    }
  ],
  values: [
    "Technical Innovation",
    "Data Accuracy",
    "Environmental Understanding",
    "Operational Excellence"
  ],
  beliefs: [
    "Digital twins enable better decisions",
    "Sensor integration drives insight",
    "AI enhances environmental understanding",
    "Real-time monitoring enables proactive management"
  ],
  norms: [
    "Data-driven approach",
    "Continuous monitoring",
    "Environmental awareness",
    "Technical precision"
  ],
  aboutPage: {
    title: "About Digital Twin Monitoring",
    subtitle: "Leading the Future of Digital Twin Technology",
    mission: {
      title: "Our Mission",
      content: "To revolutionize environmental and operational monitoring through advanced digital twin technology and AI-powered analytics."
    },
    team: [],
    values: [
      {
        title: "Technical Innovation",
        description: "Pushing the boundaries of digital twin technology and AI integration"
      },
      {
        title: "Data Accuracy",
        description: "Ensuring precise and reliable digital representations of physical environments"
      },
      {
        title: "Environmental Understanding",
        description: "Deep comprehension of complex physical and virtual landscapes"
      },
      {
        title: "Operational Excellence",
        description: "Delivering superior monitoring and analysis solutions"
      }
    ]
  },
  portfolioPage: {
    title: "Our Solutions",
    subtitle: "Comprehensive Digital Twin Monitoring Solutions",
    companies: [],
    investmentCriteria: []
  },
  solutionsPage: {
    title: "Digital Twin Solutions",
    subtitle: "Advanced Monitoring and Analytics",
    content: "Our comprehensive digital twin solutions combine sensor networks, 3D modeling, and AI-powered analytics to provide unprecedented insights into complex environments."
  },
  technologyPage: {
    title: "Our Technology",
    subtitle: "Advanced Digital Twin Platform",
    markdown: "# Digital Twin Technology\n\nOur platform combines advanced sensor networks, real-time 3D modeling, and AI-powered analytics to create comprehensive digital twin solutions."
  }
};

export default DTMConfig;
