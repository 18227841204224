import { SiteConfig } from '../sites.ts';

const config: SiteConfig = {
  company: {
    name: "GlobalDevOps Inc.",
    tagline: "Delivering world-class technology operations through an innovative fusion of elite human expertise and advanced AI systems.",
    description: "Global provider of AI-enhanced technology operations and staffing solutions. Operating international technology expert network across multiple continents. Specializing in AI Virtual CTO services and technology operations support. Delivering 24/7 service through strategically positioned global operations centers.",
    details: "GlobalDevOps Inc. (GDO) is a pioneering technology operations company that combines human expertise with advanced AI systems to deliver comprehensive technology services. With operations centers strategically positioned across Europe, the United Kingdom, Asia, and Mexico, GDO provides round-the-clock service delivery to meet the complex technological needs of Canadian innovation companies.\n\nAt the heart of GDO's offering is its flagship AI Virtual CTO service, which represents a revolutionary approach to technology operations. This service integrates elite human talent with sophisticated AI agents to provide best-in-class architecture, development, operations, and support services, addressing one of the most significant challenges facing Canadian innovation companies: access to high-quality, cost-effective technology staffing.\n\nThe company's global presence enables a robust 24/7 service delivery model, with the Mexican center focusing on North American time zone operations while Asian facilities provide mission-critical support during non-North American hours. This strategic positioning ensures continuous service availability and optimal support for clients across all time zones.\n\nThrough its partnership with Data Asset Store, GDO maintains a strong commitment to Canadian data sovereignty, ensuring all solutions comply with Canadian data governance and regulatory requirements. This commitment, combined with their innovative approach to technology operations, positions GDO as a key enabler of growth and innovation in the Canadian technology sector."
  },
  theme: {
    primary: "#0066CC",
    secondary: "#001F3F",
    accent: "#00D1B2",
    background: "#FFFFFF",
    text: "#333333"
  },
  navigation: {
    portfolioLabel: "Portfolio",
    showTechnology: true,
    showSolutions: true,
    showBlog: true,
    showNews: true,
    showHelp: true,
    showAuth: true
  },
  footerLinks: [
    { label: "Privacy Policy", href: "/privacy" },
    { label: "Terms of Service", href: "/terms" },
    { label: "Contact", href: "/contact" }
  ],
  aboutPage: {
    title: "About GlobalDevOps",
    subtitle: "Revolutionizing Technology Operations Globally",
    mission: {
      title: "Our Mission",
      content: "To revolutionize technology operations through the fusion of human expertise and AI systems, providing Canadian innovation companies with access to world-class technology talent while ensuring 24/7 service excellence through strategically positioned global operations."
    },
    team: [],
    values: [
      { title: "Technical Excellence", description: "Maintaining the highest standards in technology operations" },
      { title: "Global Collaboration", description: "Leveraging international expertise for optimal results" },
      { title: "Innovation", description: "Pushing boundaries in AI-enhanced operations" },
      { title: "Client Success", description: "Dedicated to achieving exceptional outcomes for our clients" }
    ]
  },
  portfolioPage: {
    title: "Our Portfolio",
    subtitle: "Technology Solutions That Drive Success",
    companies: [],
    investmentCriteria: []
  },
  homePage: {
    hero: {
      title: "Global Technology Operations Excellence",
      highlight: "AI-Enhanced Technology Operations",
      description: "Delivering world-class technology operations through an innovative fusion of elite human expertise and advanced AI systems."
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  },
  offerings: [
    {
      type: "service",
      name: "AI Virtual CTO",
      description: "Integrated AI and human expertise for comprehensive technology leadership",
      features: [
        "Architecture design and oversight",
        "Development team management",
        "Operations and support services",
        "AI-enhanced decision making"
      ],
      benefits: [
        "Access to world-class technology expertise",
        "Cost-effective technology staffing",
        "24/7 service availability",
        "Enhanced operational efficiency"
      ]
    },
    {
      type: "service",
      name: "Global Technology Operations",
      description: "Round-the-clock technology operations and support",
      features: [
        "24/7 service delivery",
        "Multi-region support coverage",
        "International expert network",
        "Time zone optimized operations",
        "Mexico operations center for North American coverage"
      ],
      benefits: [
        "Continuous service availability",
        "Access to global talent pool",
        "Reduced operational costs",
        "Improved service quality"
      ]
    }
  ],
  values: [
    "Technical Excellence",
    "Global Collaboration",
    "Innovation",
    "Client Success"
  ],
  beliefs: [
    "Technology expertise should be accessible and affordable",
    "AI enhances human capabilities",
    "Global talent networks drive innovation",
    "Canadian data sovereignty is essential"
  ],
  norms: [
    "24/7 operational excellence",
    "Cross-cultural collaboration",
    "Continuous service improvement",
    "Data compliance and security"
  ],
  image_prompts: [
    "Dynamic world map visualization showing GDO's global operations centers connected by glowing network lines, with real-time activity indicators and time zone overlays",
    "Modern command center environment with multiple displays showing AI-enhanced monitoring systems and global team collaboration in action",
    "Professional 3D rendering of the AI Virtual CTO system architecture, showing the seamless integration of human expertise and AI capabilities",
    "Clean, corporate visualization of 24/7 global operations workflow, with teams transitioning across time zones in a modern office setting",
    "Isometric illustration of GDO's technology stack, highlighting AI integration points and global infrastructure connections",
    "Professional team collaboration scene showing diverse international experts working with holographic AI interfaces",
    "Abstract representation of data sovereignty compliance with Canadian flag elements and secure network visualization",
    "Split-screen visualization showing simultaneous operations across global centers with modern monitoring interfaces",
    "Artistic interpretation of AI-enhanced decision making, showing the fusion of human expertise and machine intelligence in a corporate setting",
    "Technical illustration of GDO's global network architecture with emphasis on security, performance, and international connectivity"
  ]
};

export default config;
